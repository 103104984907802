<template>
  <div class="error-container">
    <div style="padding: 0px 100px;box-sizing: border-box">
      <div class="flex-row" style="margin-top: 30px;align-items: center">
        <img src="../assets/go_home.png" style="width: 44px;height: 44px;cursor: pointer" @click="goHome">
        <p class="error-list">List of Wrong Answers</p>
      </div>
      <div style="margin-top: 40px;width: 100%;padding-left:84px;box-sizing: border-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item v-for="(errorInfo,index) in dataList" :title=" (index+1)+'.'+errorInfo.quiz.question_title"
                            :name="''+(index+1)" :key="index" style="margin-bottom: 20px">
            <div style="margin-top: 10px;width: 100%;padding-left: 40px;box-sizing: border-box">
              <ErrorOptionView :quiz-detail="errorInfo.quiz"></ErrorOptionView>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/common/common.css'
import '../assets/common/font.css'
import {getUserId} from "../utils/store";
import ErrorOptionView from "../components/ErrorOptionView";
import {
  getStudentErrorList
} from '../api/fbla'
import {Loading} from "element-ui";

export default {
  name: "ErrorList",
  components: {ErrorOptionView},
  data() {
    return {
      activeName: '1',
      user_id: getUserId(),
      class_id: this.$route.query.class_id,
      dataList: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.8)',
        spinner: 'el-icon-loading',
        text: 'Loading'
      });
      this.dataList = []
      getStudentErrorList(this.user_id, this.class_id).then((res) => {
        let result = res.data.data
        for (let i = 0; i < result.length; i++) {
          let item = result[i]
          if (item.quiz != null && item.quiz != undefined){
            item.quiz['answer'] = item['answer']
            item.quiz['correct'] = item['correct']
            this.dataList.push(item)
          }
          
        }
        loadingInstance.close()
      })
    },
    goHome() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  display: none;
}



.error-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  height: 92vh;
  overflow-y: auto;
}

.error-list {
  margin-left: 40px;
  font-size: 28px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #092E7F
}

/deep/ .el-collapse {
  border-bottom-color: transparent;
  border-top-color: transparent;
}
/deep/ .el-collapse.is-active {
  border-bottom-color: transparent;
  border-top-color: transparent;
}
/deep/ .el-collapse-item__header {
  padding: 20px 20px;
  box-sizing: border-box;
  height: unset;
  min-height: 60px;
  box-shadow: 0px 3px 6px 1px rgba(9, 46, 127, 0.08);
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: #F2F6FF;
  color: #092E7F;
  cursor: pointer;
  border-bottom-width: 0px;
  border-bottom-color: transparent;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  transition: border-bottom-color .3s;
  outline: 0;
}

/deep/ .el-collapse-item__header.is-active {
  border-bottom-color: transparent;
}

</style>
