<template>
  <div class="option-container">
    <div style="width: 100%;padding:20px 40px;box-sizing: border-box">
      <div v-for="(option,index) in options" :key="index">
        <div style="display:flex;flex-direction: row;margin-bottom: 20px">
          <p class="option_index" :style="customOptionStyle(option)">
            {{ formatOptionIndexToString(index) }}</p>
          <div class="option-item" :style="customOptionStyle(option)"
               style="flex: 1;box-sizing: border-box;display: flex;flex-direction: row">
            <p style="flex: 1">{{ option.value }}</p>
            <div>
              <img src="../assets/answer_correct.png" style="width: 24px;height: 24px"
                   v-if="option.styleType == 'success'">
              <img src="../assets/answer_error.png" style="width: 24px;height: 24px"
                   v-else-if="option.styleType == 'failure'">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '../assets/common/font.css'

export default {
  name: "ErrorOptionView",
  props: {
    quizDetail: {
      type: Object,
      default: {}
    },
  },
  watch: {
    quizDetail(newVal, oldVal) {
      this.formatQuizInfo(newVal)
    }
  },
  mounted() {
    this.formatQuizInfo(this.quizDetail)
  },
  data() {
    return {
      options: [],
    }
  },
  methods: {

    customOptionStyle(option) {
      let style = {}
      let styleType = option.styleType
      if (styleType == 'normal') {
        style.background = '#FFFFFF'
        style.color = '#092E7F'
        style.borderColor = '#EEEEEE'
      } else if (styleType == 'failure') {
        style.background = '#FFF5F5'
        style.color = '#FF0000'
        style.borderColor = '#FF0000'
      } else if (styleType == 'success') {
        style.background = '#E4FAF7'
        style.borderColor = '#23B29C'
        style.color = '#23B29C'
      }
      return style
    },
    formatQuizInfo(newVal) {
      this.options = []
      let question_answer = newVal.question_answer
      var correct = newVal.correct
      var answer = newVal.answer
      let temp_options = newVal.question_options
      for (let i = 0; i < temp_options.length; i++) {
        let item = temp_options[i]
        let obj = {
          value: item
        }
        let styleType = this.getOptionStyleType((i + 1), answer, question_answer, correct)
        obj['styleType'] = styleType
        this.options.push(obj)
      }
    },
    formatOptionIndexToString(index) {
      switch (index) {
        case 0:
          return 'A'
        case 1:
          return 'B'
        case 2:
          return 'C'
        case 3:
          return 'D'
        case 4:
          return 'E'
        case 5:
          return 'F'
        case 6:
          return 'G'
        case 7:
          return 'H'
      }
    },
    // //normal success ,failure
    getOptionStyleType(index, answer, question_answer, correct) {
      //还要显示正确答案
      var styleType = 'normal'
      if (correct) {
        if (index == answer) {
          styleType = 'success'
        }
      } else {
        if (index == question_answer) {
          styleType = 'success'
        } else if (index == answer) {
          styleType = 'failure'
        }
      }
      return styleType
    },
  }
}
</script>

<style scoped lang="less">

.option-container {
  width: 100%;
  background: #FAFCFF;
  box-shadow: 0px 3px 6px 1px rgba(9, 46, 127, 0.08);
  border-radius: 10px 10px 10px 10px;
}


.option_index {
  margin-right: 20px;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  font-size: 14px;
  font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #092E7F;
}

.option-item {
  padding: 13px 20px;
  box-sizing: border-box;
  width: 100%;
  background: #FFFFFF;
  font-size: 14px;
  font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #092E7F;
  border: 1px solid #EEEEEE;
}

.option-item:last-child {
  margin-bottom: 0px;
}

</style>
